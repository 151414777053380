(function defaultScript($, Drupal) {
  Drupal.behaviors.default_list = {
    attach(context) {

      if ($('.path-search').length) {
        $('#edit-keywords').focus();
      }

    },
  };
}(jQuery, Drupal));
